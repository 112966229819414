<template>
    <div class="wrapper -registro">
        <div class="top container row-between">
            <router-link to="/" class="logo-container">
                <img class="logo" src="/img/logo-uc3m-alumni.png" alt="Uc3m Alumni" />
            </router-link>
        </div>

        <div class="contenedor-formulario">
            <h1 v-if="usertype == 'mentee'">Actualiza tus datos como Mentee</h1>
            <h1 v-if="usertype == 'mentor'">Actualiza tus datos como Mentor/a</h1>

            <ValidationObserver ref="form" v-slot="{ failed, valid }">
                <form @submit.prevent="validate" class="formulario" :valid="valid" :failed="failed">
                    <template v-if="paso == 1">
                        <legend>Datos Personales</legend>

                        <p class="tip">*Campos obligatorios</p>

                        <div class="contenedor-input -middle readonly">
                            <label for="dni">
                                DNI, NIE o pasaporte*
                                <popup-info :data="'ayuda-login'"></popup-info>
                            </label>
                            <input type="text" v-model="data.dni" name="dni" id="dni" readonly
                                title="Campo no editable" />
                        </div>

                        <div class="contenedor-input -middle" v-if="usertype == 'mentee'">

                            <label for="nia">NIA<popup-info :data="'ayuda-nia'">
                                </popup-info></label>
                            <input type="text" v-model="data.nia" name="nia" id="nia">

                        </div>

                        <div class="contenedor-input -middle readonly">
                            <label for="nombre">Nombre*</label>
                            <input type="text" v-model="data.name" name="nombre" id="nombre" readonly
                                title="Campo no editable" />
                        </div>

                        <div class="contenedor-input -middle readonly">
                            <label for="nombre">Primer apellido*</label>
                            <input type="text" v-model="data.surname" name="nombre" id="nombre" readonly
                                title="Campo no editable" />
                        </div>

                        <div class="contenedor-input -middle">

                            <label for="apellidos">Segundo apellido</label>
                            <input type="text" v-model="data.surname_2" name="apellidos" id="apellidos" />

                        </div>

                        <div class="contenedor-input -middle">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <label for="pais">País de residencia*</label>

                                <select :class="classes" v-model="data.country" name="pais" id="pais">
                                    <option value>País de residencia</option>
                                    <option :value="pais" :key="key" v-for="(pais,key) in domains.countries">{{ pais }}
                                    </option>
                                </select>

                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="contenedor-input -middle" v-show="data.country != 'España'">
                            <ValidationProvider :rules="(data.country != 'España') ? 'required' : ''" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="provincia">Provincia de residencia*</label>
                                <input type="text" :class="classes"
                                    v-model="data.province" name="provincia" id="provincia">
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input -middle" v-show="data.country == 'España'">
                            <ValidationProvider :rules="(data.country == 'España') ? 'required' : ''" v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <label for="provinciaselect">Provincia de residencia*</label>
                                <select  :class="classes" v-model="data.province" name="provinciaselect" id="provinciaselect">
                                    <option value="">Provincia de residencia</option>
                                    <option :value="provincia" :key="key" v-for="(provincia,key) in domains.provinces">
                                        {{provincia}}</option>
                                </select>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>


                        <div class="contenedor-input -middle">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <label for="email">Email personal*</label>
                                <input type="text" :class="classes" v-model="data.email" name="email" id="email" />
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input -middle">
                            <ValidationProvider :rules="'email'" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <label for="email">Email UC3M</label>
                                <input type="text" :class="classes" v-model="data.email_internal" name="email"
                                    id="email" />
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input -middle">
                            <ValidationProvider rules="numeric|required"
                                v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <label for="phone">Teléfono*</label>
                                <input type="tel" v-model="data.phone" name="phone" id="phone" :class="classes" />
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input -middle" v-if="showbirthday">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <label for>Fecha de nacimiento*</label>
                                <year-datepicker v-model="data.birthday_date" :name="'start_date'" :class="classes">
                                </year-datepicker>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-radios">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }"
                                class="row-center">
                                <h4>Género*:</h4>
                                <div class="radio">
                                    <input type="radio" name="gender" v-model="data.gender" id="gender-hombre"
                                        :value="0" :class="classes" />
                                    <label for="gender-hombre">Masculino</label>
                                </div>

                                <div class="radio">
                                    <input type="radio" name="gender" v-model="data.gender" id="gender-mujer" :value="1"
                                        :class="classes" />
                                    <label for="gender-mujer">Femenino</label>
                                </div>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-boton row-between">
                            <a>Paso 1 de 2</a>
                            <a class="btn -skull-white" @click="next">Siguiente</a>
                        </div>
                    </template>

                    <template v-if="paso == 2">
                        <legend>Datos Profesionales</legend>

                        <div class="contenedor-input -middle" v-if="domains.experience">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <label for="empresa">
                                    Experiencia laboral*
                                    <popup-info :data="'experiencia-total'"></popup-info>
                                </label>
                                <select :class="classes" v-model="data.profesional_xp" name="empresa" id="empresa">
                                    <option value>Selecciona experiencia laboral</option>
                                    <option :key="key" v-for="(n,key) in domains.experience" :value="key">{{ n }}
                                    </option>
                                </select>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input" v-if="data.profesional_xp != 0">
                            <ValidationProvider :rules="(usertype == 'mentor') ? 'required' : ''"
                                v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <label for="empresa">Empresa actual{{ (usertype == 'mentor') ? '*' : '' }}</label>
                                <input type="text" :class="classes" v-model="data.profesional_current_company"
                                    name="empresa" id="empresa" />
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input" v-if="data.profesional_xp != 0">
                            <ValidationProvider :rules="(usertype == 'mentor') ? 'required' : ''"
                                v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <label for="cargo">Cargo actual{{ (usertype == 'mentor') ? '*' : '' }}</label>
                                <input type="text" :class="classes" v-model="data.profesional_current" name="cargo"
                                    id="cargo" />
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input">
                            <label for="empresa">Perfil Linkedin</label>
                            <input type="text" v-model="data.profesional_linkedin" name="linkedin" id="linkedin" />
                        </div>

                        <div class="contenedor-input" v-if="usertype == 'mentor'">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <label for="resume">Breve reseña profesional*</label>
                                <textarea :class="classes" v-model="data.profesional_resumen" name="resume"
                                    id="resume"></textarea>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <legend>Preferencias de mentoría</legend>

                        <div class="contenedor-input -middle" v-if="domains.preferences">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <label for="preferenciamentoria">Modalidad de mentoría*</label>
                                <select :class="classes" 
                                    name="preferenciamentoria" id="preferenciamentoria" >
                                    <option value>Selecciona preferencia..</option>
                                        <option  :key="key" v-for="(n,key) in domains.preferences" :value="key">{{ n }}
                                        </option>
                                </select>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                


                        <div class="contenedor-multiselect -middle -white" v-if="domains.area">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <label for="area">Áreas profesionales*</label>
                                <multiselect :class="classes" :multiple="true" track-by="text" label="text"
                                    v-model="data.area" :options="domains.area" :searchable="true"
                                    :close-on-select="true" :show-labels="false" placeholder="Selecciona el área">
                                </multiselect>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-multiselect -middle -white" v-if="sectores">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <label for="area">Sectores profesionales*</label>
                                <multiselect :class="classes" :multiple="true" :track-by="'text'" :label="'text'"
                                    v-model="data.sector" :options="sectores" :searchable="true" :close-on-select="true"
                                    :show-labels="false" placeholder="Seleccione uno o varios sectores"></multiselect>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-multiselect -middle -white" v-if="domains.language">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <label for>Idiomas en mentorías*</label>
                                <multiselect :class="classes" :multiple="true" track-by="text" label="text"
                                    v-model="data.language" :options="domains.language" :searchable="true"
                                    :close-on-select="true" :show-labels="false" placeholder="Selecciona los idiomas">
                                </multiselect>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-input">
                            <ValidationProvider rules="required" v-slot="{ errors, ariaInput, ariaMsg, classes }">
                                <label for="resume">Motivación para la participación*</label>
                                <textarea :class="classes" v-model="data.preferences_motivation" name="resume"
                                    id="resume"></textarea>
                                <span v-bind="ariaMsg" class="error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <div class="contenedor-boton row-between">
                            <a class="back" @click="paso--">Volver</a>
                            <a class="btn -skull-white" @click="validate" :loading="loading">Actualizar datos</a>
                        </div>
                    </template>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex';
    import router from '@/router';

    export default {
        name: 'registro',
        mounted() {
            this.requestDomains();
            this.data = this.user;
            if (this.data) {
                this.data.gender = null;
                   
                if(this.user.preferences_participation){
                    this.data.preferences_participation = null;
                }
            

                if (this.data.birthday_date) {
                    this.data.birthday_date = new Date(this.data.birthday_date, 1, 1);
                }else{
                    delete this.data.birthday_date;
                }

                this.showbirthday = true;
            }
        },
        data: () => ({
            data: {
                'dni': '',
                'country': '',
                'province': '',
                'email': '',
                'email_internal': '',
                'phone': '',
                'password': '',
                'password_confirmation': '',
                'name': '',
                'surname': '',
                'surname_2': '',
                'gender': '',
                'title': '',
                'profesional_current': '',
                'profesional_current_company': '',
                'profesional_resumen': '',
                'profesional_xp': '',
                'town': '',
                'area': [],
                'sector': [],
                'preferences_participation': null,
                'preferences_motivation': '',
                'type': '',
                'ability': [],
                'language': [],
                'observations_other_abilities':null,
                'visible_by_others': false,
                'username': '',
                'extra_program_id': null
            },
            preferences_participation : '',
            paso: 1,
            loading: false,
            sectores: [],
            showbirthday: false


        }),
        methods: {
            ...mapActions([
                'requestRegister',
                'requestDomains',
                'rgpdSave',
                'requestsector'
            ]),
            next() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.paso++;
                        this.$refs.form.reset()
                    }
                });
            },
            cleanArrays() {
                let clean = ['ability', 'language', 'sector', 'area'];
                clean.forEach(e => {
                    let array = new Array();
                    this.data[e].forEach(v => {
                        array.push(v.id);
                    })
                    this.data[e] = array;
                });
            },
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        let data = JSON.parse(JSON.stringify(this.data));
                        if (data.birthday_date && data.birthday_date.includes("-")) {
                            data.birthday_date = Number(data.birthday_date.split("-")[0]);
                            this.data.birthday_date = new Date(this.data.birthday_date);
                        }
                        let rgpd_data = JSON.parse(sessionStorage.getItem('rgpd_form_values'));
                        data = { ...data, ...rgpd_data };
                        sessionStorage.clear('rgpd_form_values');

                        ['ability', 'language', 'sector', 'area'].forEach(e => {
                            if (Array.isArray(data[e])) {
                                let array = new Array();
                                data[e].forEach(v => {
                                    array.push(v.id);
                                })
                                data[e] = array;
                            } else {
                                data[e] = Object.keys(data[e]);
                            }
                        });

                        this.data.preferences_participation = document.getElementById("preferenciamentoria").value;

                        this.rgpdSave(data).then(() => {
                            this.loading = false;
                            setTimeout(() => {
                                router.push({ name: 'home' })
                            }, 3000);
                        })
                    }
                });
            },
        },
        computed: {
            ...mapGetters({
                domains: 'getDomains',
                user: 'getuser',
                usertype: 'getUserType',
            })
        },
        watch: {
            'user'() {
                this.data = this.user;

                if (this.data) {
                    this.data.gender = null;
                //     if(this.user.preferences_participation){
                //     this.data.preferences_participation = null;
                // }
            
                    // if (this.data.birthday_date) {
                    //     this.data.birthday_date = new Date(this.data.birthday_date, 1, 1);
                    // }
                }
            },
            'data.area'() {
                this.requestsector(this.data.area).then(r => {
                    this.sectores = r;
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .contenedor-formulario h1 {
        font-size: 40px;
    }
</style>